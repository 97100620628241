import grahamScan, { grahamScanPoints } from "./GrahamScan.logic";
import { getLowestOrHighestPoint, pointsToLineList, orientation } from "./helpers.logic";
import Line from "./Line.logic";
import Point from "./Point.logic";

const divideIntoGroups = (points: Array<Point>, numberOfGroups: number, sortThePoints=false) => {
	const groups:Array<Array<Point>> = [];
	const pointCopy = [...points];

	if (sortThePoints) pointCopy.sort((p1, p2) => p1.x - p2.x);

	const pointsPerGroup = Math.floor(pointCopy.length / numberOfGroups);
	let groupsWithOneMorePoint = pointCopy.length % numberOfGroups;

	let start = 0;
	for (let i = 0; i < numberOfGroups; i++) {
		let inCurrentGroup = pointsPerGroup;
		if (groupsWithOneMorePoint > 0) {
			inCurrentGroup++;
			groupsWithOneMorePoint--;
		}
		groups.push(pointCopy.slice(start, start + inCurrentGroup));
		start += inCurrentGroup;
	}

	return groups;
}

const isTangent = (point: Point, pointOnHull: Point, previous: Point, next: Point) => {
	return orientation(point, pointOnHull, previous) === orientation(point, pointOnHull, next);
}

const findTangent = (point: Point, hull: Array<Point>) => {
	const tangents = [];
	const n = hull.length;
	for (let i = 0; i < n; i++) {
		if (hull[i] === point) continue;
		if (isTangent(point, hull[i], hull[(i + n - 1) % n], hull[(i + 1) % n]))
			tangents.push(hull[i])
	}
	return tangents;
}

const findTangents = (point: Point, hulls: Array<Array<Point>>) => {
	const tangents = [];
	for (let hull of hulls)
		tangents.push(...findTangent(point, hull));
	return tangents;
}

const prepareGroupsForAnimation = (groups: Array<Array<Line>>, useNewColor=false) => {
	const res = [];
	for (let group of groups) {
		group.forEach(line => line.color = 'grey');
		res.push(...group)
	}
	return res;
}

const prepareTangentsForAnimation = (point: Point, tangentsPoints: Array<Point>) => {
	const tangentLines: Array<Line> = [];
	for (let p of tangentsPoints) {
		const newTangent = new Line(point, p);
		newTangent.color = 'green';
		tangentLines.push(newTangent);
	}
	return tangentLines;
}

export default function* chan(points: Array<Point>, animated: boolean = false, sortPoints: boolean = true) {
	if (points.length <= 1) return [];
	if (points.length === 2) return [new Line(points[0], points[1])];

	// 1) divide points into groups and find the individual convex hull
	const groups = divideIntoGroups(points, points.length / 10, sortPoints);
	let groupsAsConvexHulls: Array<Array<Line>> = [[]];
	for (const group of groups) {
		let isFirstIteration = true;
		for (const convexHull of grahamScan(group, animated)) {
			if (!isFirstIteration) groupsAsConvexHulls.pop();
			groupsAsConvexHulls.push(convexHull);
			yield prepareGroupsForAnimation(groupsAsConvexHulls, isFirstIteration);
			if (isFirstIteration) isFirstIteration = false;
		}
	}

	const animatedGroups = prepareGroupsForAnimation(groupsAsConvexHulls, false);
	const convexHullsOfGroups = groups.map(group => grahamScanPoints(group));

	// 2) connect convex hulls
	const lowestPoint = getLowestOrHighestPoint(points, false);
	const convexHull = [lowestPoint];
	do {
		const vertex = convexHull[convexHull.length - 1];
		const potentialNextPoints = findTangents(vertex, convexHullsOfGroups);
		let nextPoint;
		if (convexHull.length === 1) nextPoint = potentialNextPoints[0];
		else nextPoint = convexHull[convexHull.length - 2];
		for (let point of potentialNextPoints) {
			if (orientation(vertex, nextPoint, point)) nextPoint = point;
			if (animated) yield [...animatedGroups, ...prepareTangentsForAnimation(vertex, potentialNextPoints), ...pointsToLineList([...convexHull, point], false, 'red')]
		}
		yield [...animatedGroups, ...prepareTangentsForAnimation(vertex, potentialNextPoints), ...pointsToLineList(convexHull, false, 'red')]
		convexHull.push(nextPoint);
		yield [...animatedGroups, ...prepareTangentsForAnimation(vertex, potentialNextPoints), ...pointsToLineList(convexHull, false, 'red')]
	} while (convexHull[convexHull.length - 1] !== lowestPoint)

	const tangents = findTangents(lowestPoint, convexHullsOfGroups);
	yield [...animatedGroups, ...prepareTangentsForAnimation(lowestPoint, tangents), ...pointsToLineList(convexHull, true, 'red')]
	yield pointsToLineList(convexHull, true, 'red');
}