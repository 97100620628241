import Line from "./Line.logic";
import Point from "./Point.logic";
import { getLowestOrHighestPoint, pointsToLineList } from "./helpers.logic";
import { resetPointColor } from "./helpers.logic";


const getOrderedPoints = (points: Array<Point>, lowestPoint: Point): Array<Point> => {
	const lines: Array<[Line, Point]> = [];
	points.forEach(point => {
		if (point === lowestPoint) return;
		lines.push([new Line(lowestPoint, point), point])
	})
	lines.sort((a, b) => b[0].angle - a[0].angle)
	return lines.map(([line, point]) => point);
}

export const makesRightTurn = (p1: Point, p2: Point, p3: Point): boolean => {
	const vector1 = [p2.x - p1.x, p2.y - p1.y];
	const vector2 = [p3.x - p2.x, p3.y - p2.y];
	const crossProduct = vector1[0] * vector2[1] - vector1[1] * vector2[0];
	return crossProduct >= 0;
}

// reminder: merge both implementations of grahamScan
// 	why I couldn't do it yet:
// 	the grahamScan - Function that is used for animation has to set many color and other animation stuff during execution: (
export function grahamScanPoints(points: Array<Point>) {
	// Es gibt keine konvexe Hülle
	if (points.length <= 3) return points;

	let lowestPoint = getLowestOrHighestPoint(points);

	const allPoints = getOrderedPoints(points, lowestPoint);
	const stack = [allPoints[1], allPoints[0], lowestPoint];

	for (let i = 0; i < allPoints.length; i++) {
		while (stack.length >= 2 && makesRightTurn(stack[1], stack[0], allPoints[i])) {
			stack.shift()
		}
		stack.unshift(allPoints[i]);
	}
	return stack;
}

export default function* grahamScan(points: Array<Point>, animated: boolean = true) {
	// Es gibt keine konvexe Hülle
	if (points.length <= 1) return [];
	if (points.length === 2) return [new Line(points[0], points[1])];

	resetPointColor(points);
	let lowestPoint = getLowestOrHighestPoint(points);
	lowestPoint.color = 'green';

	const allPoints = getOrderedPoints(points, lowestPoint);
	const stack = [allPoints[1], allPoints[0], lowestPoint];

	for (let i = 0; i < allPoints.length; i++) {
		while (stack.length >= 2 && makesRightTurn(stack[1], stack[0], allPoints[i])) {
			stack.shift()
		}
		stack.unshift(allPoints[i]);
		if (animated) {
			stack[0].color = 'green';
			yield pointsToLineList(stack, false);
		}
	}
	yield pointsToLineList(stack);
}