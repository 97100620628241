import React, { useState, useEffect, useRef, MouseEvent } from 'react';
import PointComponent from '../components/Point';
import LineComponent from './Line';
import grahamScan from '../Logic/GrahamScan.logic';
import jarvisMarch from '../Logic/JarvisMarch.logic';
import chan from '../Logic/Chan.logic';
import minDistance from '../Logic/MinDistance.logic';
import Point from '../Logic/Point.logic'
import Line from '../Logic/Line.logic';
import './Animation.scss'
import { resetPointColor } from '../Logic/helpers.logic'
import Darkmode from './Darkmode';


export default function Animation() {
	const [points, setPointsOriginal] = useState<Array<Point>>([]);
	const setPoints = (points: Array<Point>, algorithmId: number) => {
		setPointsOriginal(points);
		updateGenerator(algorithmId, points, isAnimationDetailled);
	}
	const [lines, setLines] = useState<Array<Line>>([]);
	const containerRef = useRef(null);
	const [containerDimensions, setContainerDimensions] = useState([0, 0]);
	const [algorithmId, setAlgorithmId] = useState(0);
	const [algorithmInterval, setAlgorithmInterval] = useState(null);
	const [breakLength, setBreakLength] = useState(100);
	const [isAnimationDetailled, setAnimationDetailled] = useState(true);
	const [arePointsSorted, setArePointsSorted] = useState(true);
	const algorithms = [jarvisMarch, grahamScan, chan, chan]
	const defaultGenerators: Array<Generator> = [];
	for (let algorithm of algorithms) defaultGenerators.push(algorithm([]));
	const [generators, setGenerators] = useState(defaultGenerators);

	const updateGenerator = (pos: number, points: Array<Point>, isDetailed = true, arePointsSorted = true) => {
		const generatorCopy = [...generators];
		if (pos === 2) generatorCopy[pos] = chan(points, isDetailed, arePointsSorted);
		else generatorCopy[pos] = algorithms[pos](points, isDetailed);
		setGenerators(generatorCopy);
		resetPointColor(points);
	}

	useEffect(() => {
		updateGenerator(algorithmId, points, isAnimationDetailled, arePointsSorted);
	}, [algorithmId, points, isAnimationDetailled, arePointsSorted]);

	const constructPoint = (e: MouseEvent<HTMLDivElement>) => {
		const rect = e.currentTarget.getBoundingClientRect();
		const x = (e.clientX - rect.x) / rect.width;
		const y = (e.clientY - rect.y) / rect.height;
		if (x >= 1. || x <= 0 || y >= 1. || y < 0) return;
		setPoints([...points, new Point(x, y)], algorithmId)
	}

	const makeOneStepOfAlgorithm = () => {
		const res = generators[algorithmId].next().value;
		if (!res) {
			updateGenerator(algorithmId, points, isAnimationDetailled);
			return true;
		}
		setLines(res);
		return false;
	}

	const stopAlgorithm = () => {
		if (algorithmInterval === null) return;
		clearInterval(algorithmInterval);
		setAlgorithmInterval(null);
	}

	const runAlgorithm = () => {
		if (algorithmInterval) stopAlgorithm();
		const interval: any = setInterval(function () {
			const isAlgorithmDone = makeOneStepOfAlgorithm();
			if (isAlgorithmDone) {
				clearInterval(interval);
				setAlgorithmInterval(null);
			}
		}, breakLength);
		setAlgorithmInterval(interval);
	}

	const clearPoints = () => {
		if (algorithmInterval) return;
		setPoints([], algorithmId);
		setLines([]);
	}

	const generatePoints = () => {
		if (algorithmInterval) return;
		setPoints([...points, ...Point.generateRandPoints(50)], algorithmId)
	}

	const changeTab = (newTab: number) => {
		setAlgorithmId(newTab);
		updateGenerator(newTab, points, isAnimationDetailled);
	}

	const changeAnimationSpeed = (e: any) => {
		const newBreak = Number(e.target.value);
		setBreakLength(newBreak);
		if (algorithmInterval) {
			stopAlgorithm();
			runAlgorithm();
		}
	}

	const changeAnimationDetail = (e: React.ChangeEvent<HTMLInputElement>) => {
		updateGenerator(algorithmId, points, !isAnimationDetailled);
		setAnimationDetailled(!isAnimationDetailled);
	}

	useEffect(() => {
		if (!containerRef.current) return;
		const resizeObserver = new ResizeObserver(e => {
			const divSize = e[0].contentRect;
			setContainerDimensions([divSize.width, divSize.height]);
		});
		resizeObserver.observe(containerRef.current)
	}, [])

	return (
		<>
			{
				algorithmId === 3 ?
				<h1>Finding the two closest Points</h1>
					: 
				<h1>Finding convex hulls</h1>
			}
			<Darkmode />
			<div className='algorithm'>
				<div className={`button ${algorithmId === 0 && 'selected'}`} onClick={() => changeTab(0)}>Jarvis March</div>
				<div className={`button ${algorithmId === 1 && 'selected'}`} onClick={() => changeTab(1)}>Graham Scan</div>
				<div className={`button ${algorithmId === 2 && 'selected'}`} onClick={() => changeTab(2)}>Chan's Algorithm</div>
				<div className={`button ${algorithmId === 3 && 'selected'}`} onClick={() => changeTab(3)}>Smallest Distance</div>
			</div>
			<div className="animation" onClick={constructPoint} ref={containerRef}>
				{
					points.map((point, i) => <PointComponent p={point} key={`point-${i}`} />)
				}
				{
					lines.map((line, i) => <LineComponent line={line} key={`line-${i}`} containerDimensions={containerDimensions} />)
				}
			</div>
			<div className={`controls ${algorithmInterval && 'greyed-out'}`}>
				<div className='drawing-settings'>
					<div className='button' onClick={generatePoints}>
						Generate 50 random points
					</div>
					<div className='button' onClick={clearPoints}>
						Clear all points
					</div>
				</div>
				<div className='algorithm-settings'>
					{algorithmId === 3 ?
						<>
							<div className='button' onClick={() => setLines(minDistance(points, containerDimensions))}>
								Go!
							</div>
						</> :
						<>
							<>
								<div className='button' onClick={runAlgorithm}>
									Go!
								</div>
								<div className='button' onClick={makeOneStepOfAlgorithm}>
									Make one step!
								</div>
								<div className='button' onClick={() => { updateGenerator(algorithmId, points, isAnimationDetailled); setLines([]); }}>
									Reset animation
								</div>
								<label className='button speed-label' title="Sollen bei der Animation zum Verständnis auch die Zwischenschritte angezeigt werden?">
									Detailled animation
									<input type={'checkbox'} checked={isAnimationDetailled} onChange={changeAnimationDetail} />
								</label>
							</>
							{
								algorithmId === 2 &&
								<label className='button'>
									Sort points before dividing into groups
									<input type={'checkbox'} checked={arePointsSorted} onChange={() => { setArePointsSorted(!arePointsSorted) }} />
								</label>
							}
							<label className='button not-greyed-out speed-label'>
								Animation Speed
								<input type={'range'} min=".1" max="500" defaultChecked={true} onChange={changeAnimationSpeed} />
							</label>
							{
								algorithmInterval &&
								<div className='button not-greyed-out' onClick={() => stopAlgorithm()}>
									Abbrechen
								</div>
							}
						</>
					}
				</div>
			</div>
		</>
	);
}
