import Point from "./Point.logic";
import Line from "./Line.logic";

const minDistance = (points: Array<Point>, containerDimensions: Array<number>) => {
	points.sort((p1, p2) => p1.x - p2.x);
	
	const pointsSortedByY = [...points];
	pointsSortedByY.sort((p1, p2) => p1.y - p2.y)

	const res = minDistanceInternal(points, pointsSortedByY, containerDimensions);
	res[0].p1.color = 'red';
	res[0].p2.color = 'red';

	return res;
}

// convention: 'points' is sorted by x-coordinate
const minDistanceInternal = (points: Array<Point>, pointsSortedByY: Array<Point>, containerDimensions: Array<number>):Array<Line> => {
	// error can't find distance between 0 or 1 point
	if (points.length <= 1) throw Error("Es müssen mehr als ein Punkt gegeben werden!");

	// base cases: brute force all combinations
	if (points.length === 2)
		return [new Line(points[0], points[1])];
	if (points.length === 3) {
		const d1 = Point.getDist(points[0], points[1], containerDimensions);
		const d2 = Point.getDist(points[0], points[2], containerDimensions);
		const d3 = Point.getDist(points[1], points[2], containerDimensions);
		if (d1 < d2 && d1 < d3) return [new Line(points[0], points[1])];
		else if (d2 < d1 && d2 < d3) return [new Line(points[0], points[2])];
		else if (d3 < d1 && d3 < d2) return [new Line(points[1], points[2])];
	}

	// divide points in middle by vertical line (they're still sorted by x-coordinate)
	const midIndex = Math.floor(points.length / 2);

	const leftPoints = points.slice(0, midIndex);
	const rightPoints = points.slice(midIndex);

	// 1. minDistance is in left or in right groups of points
	let minDist, point1, point2;
	
	const [minLineLeft] = minDistanceInternal(leftPoints, getIntersection(leftPoints, pointsSortedByY), containerDimensions);
	point1 = minLineLeft.p1;
	point2 = minLineLeft.p2;
	minDist = Point.getDist(point1, point2, containerDimensions)

	const [minLineRight] = minDistanceInternal(rightPoints, getIntersection(rightPoints, pointsSortedByY), containerDimensions);

	const minDist2 = Point.getDist(minLineRight.p1, minLineRight.p2, containerDimensions);
	if (minDist2 < minDist) {
		point1 = minLineRight.p1;
		point2 = minLineRight.p2;
		minDist = minDist2;
	}

	const xCoordinateVerticalLine = (points[midIndex - 1].x + points[midIndex].x) / 2;

	const pointsNearMiddle = getPointsCloseToVerticalLine(points, minDist, xCoordinateVerticalLine)

	// 2. minDistance is between Point left of and right of the line
	for (let i = 0; i < pointsNearMiddle.length; i++) {
		const currPoint = pointsNearMiddle[i];
		// test the following 7 points
		for (let j = 1; j <= 7; j++) {
			if (i + j >= pointsNearMiddle.length) break;
			const nextPoint = pointsNearMiddle[i + j];
			const currDist = Point.getDist(currPoint, nextPoint, containerDimensions);
			if (currDist < minDist && currDist !== 0) {
				minDist = currDist;
				point1 = currPoint;
				point2 = nextPoint;
			}
		}
	}
	return [new Line(point1, point2)];
}

const getIntersection = (template: Array<Point>, sortedPoints: Array<Point>) => {
	const res = [];
	for (let p of sortedPoints) {
		if (template.includes(p))
			res.push(p)
	}
	return res;
}

const getPointsCloseToVerticalLine = (points: Array<Point>, minDistanceSoFar: number, xCoordinateSeparator: number) => {
	const res = [];
	for (let p of points) {
		if (Math.abs(p.x - xCoordinateSeparator) < minDistanceSoFar) res.push(p)
	}
	return res;
}



export default minDistance;