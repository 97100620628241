import Point from "./Point.logic";
import { getLowestOrHighestPoint, pointsToLineList, resetPointColor, orientation } from "./helpers.logic";
import Line from "./Line.logic";

function* jarvisMarch(points: Array<Point>, animated: boolean = true) {
	if (points.length <= 1) return [];
	if (points.length === 2) return [new Line(points[0], points[1])];
	let pivotPoint = getLowestOrHighestPoint(points, false);
	const convexHull = [pivotPoint];
	let nextPoint = new Point(-1, -1);
	do{
		for (let i = 0; i < points.length; i++){
			let point = points[i];
			if (point === pivotPoint) continue;
			if (nextPoint.x === -1 && nextPoint.y === -1) nextPoint = point;
			if (orientation(pivotPoint, nextPoint, point)) nextPoint = point;
			if (animated) {
				// for animation
				if (convexHull.includes(point)) continue;
				convexHull.push(point)
				const res = pointsToLineList(convexHull, false)
				res[res.length - 1].color = 'green';
				point.color = 'green';
				yield res;
				convexHull.pop();
			}
		}
		resetPointColor(points);
		convexHull.push(nextPoint);
		yield pointsToLineList(convexHull, false);
		let temp = pivotPoint
		pivotPoint = nextPoint;
		nextPoint = temp;
	} while (pivotPoint !== convexHull[0])

	// if (animated) yield pointsToLineList(convexHull);
	yield pointsToLineList(convexHull);
}

export default jarvisMarch;