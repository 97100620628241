import React from 'react'
import './App.scss';
import Animation from './components/Animation';

function App() {
  return (
    <div className='App'>
      <Animation />
    </div>
  )
}

export default App;