import React, { FC } from 'react'
import Point from '../Logic/Point.logic'
import './Point.scss'

interface PointProps{
	p: Point,
	key: string
}

const PointComponent:FC<PointProps> = props => {
	const style = {
		left: `${props.p.x * 100}%`,
		top: `${props.p.y * 100}%`,
		backgroundColor: props.p.color
	}

	return (
		<div className='point' style={style}>
		</div>
	)
}

export default PointComponent;