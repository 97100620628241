import Point from "./Point.logic";

export default class Line {
	p1: Point;
	p2: Point;
	length: number;
	angle: number;
	color: string;
	constructor(p1: Point, p2:Point) {
		this.p1 = p1;
		this.p2 = p2;
		this.length = ((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2) ** 0.5;
		this.angle = Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI;
		this.angle = (this.angle + 180) % 360
		this.color = 'red';
	}

}