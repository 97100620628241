import { FC } from 'react'
import Line from '../Logic/Line.logic'
import './Line.scss'

interface LineProps{
	line: Line,
	key: string,
	containerDimensions: number[]
}

const LineComponent:FC<LineProps> = props => {
	const line = props.line;

	const p1 = line.p1;
	const p2 = line.p2;
	const containerWidth = props.containerDimensions[0];
	const containerHeight = props.containerDimensions[1];
	const angle = Math.atan2(containerHeight * (p2.y - p1.y), containerWidth * (p2.x - p1.x)) * 180 / Math.PI;
	
	const length = (containerWidth**2 * (p2.x - p1.x) ** 2 + containerHeight**2 * (p2.y - p1.y)**2)**0.5
	
	const style = {
		width: `${length}px`,
		left: `${line.p1.x * 100}%`,
		top: `${line.p1.y * 100}%`,
		backgroundColor: props.line.color,
		transform: `rotate(${angle}deg)`
	}

	return (
		<div className='line' style={style}>
		</div>
	)
}

export default LineComponent
