import Point from "./Point.logic";
import Line from "./Line.logic";

export const orientation = (p1: Point, p2: Point, p3: Point): boolean => {
	return ((p2.x - p1.x) * (p3.y - p1.y) > (p3.x - p1.x) * (p2.y - p1.y));
}


export const getLowestOrHighestPoint = (points: Array<Point>, highestPointWanted: boolean = false) => {
	let wantedPoint = points[0]
	for (let i = 0; i < points.length; i++) {
		const currPoint = points[i];
		if (highestPointWanted) {
			if (currPoint.y < wantedPoint.y) wantedPoint = currPoint
		} else {
			if (currPoint.y > wantedPoint.y) wantedPoint = currPoint
		}
	}
	return wantedPoint;
}

export const pointsToLineList = (points: Array<Point>, closeOff=true, color='red') => {
	const lines = [];
	const l = points.length;
	for (let i = 0; i < l - 1; i++)
		lines.push(new Line(points[i], points[i + 1]))
	if (closeOff) lines.push(new Line(points[l - 1], points[0]))

	lines.forEach(l => l.color = color);

	return lines
}

export const resetPointColor = (points: Array<Point>) => {
	points.forEach(p => p.color = 'var(--line-color)')
}