export default class Point {
	x: number;
	y: number;
	color: string;

	constructor(x: number, y: number) {
		this.x = x;
		this.y = y;
		this.color = 'var(--line-color)';
	}

	static getDist = (p1: Point, p2: Point, containerDimensions: Array<number> = [1, 1]) => {
		const [containerWidth, containerHeight] = containerDimensions;
		return (containerWidth**2 * (p2.x - p1.x) ** 2 + containerHeight**2 * (p2.y - p1.y)**2)**0.5
	}

	static getRandNumber = (n = 4) => {
		let res = 0;
		for (let i = 0; i < n; i++) res += Math.random();
		return res / n;
	}


	static generateRandPoints = (num = 50) => {
		const newPoints = [];
		for (let i = 0; i < num; i++)
			newPoints.push(new Point(Point.getRandNumber(1), Point.getRandNumber(1)));
		return newPoints;
	}

}